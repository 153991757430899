<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div style="font-size: 20px">
        {{ nueva ? 'Nueva' : `${editar ? 'Editar' : 'Detalle'}` }} Campaña
      </div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-if="!nueva && campania.estado_id == 1"
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <!-- encabezado -->
    <v-row class="pt-4">
      <v-col cols="12" sm="6" class="py-0">
        <v-text-field
          v-model.trim="nombre"
          label="Nombre"
          :readonly="!editar"
          :filled="!editar"
          :autofocus="editar"
          outlined
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3" class="py-0">
        <v-autocomplete
          v-model="tipo"
          label="Tipo"
          item-text="nombre"
          item-value="id"
          :items="tipos_camp"
          :readonly="!nueva"
          :filled="!nueva"
          outlined
          dense
          @change="sucursal = []; sucursal_sola = null; efectivo = false; forma_cobro = []; fidelizacion = 0"
        ></v-autocomplete>
      </v-col>
      <v-col v-if="!nueva" cols="6" sm="3" md="2" class="py-0">
        <v-autocomplete
          v-model="estado_id"
          label="Estado"
          item-text="nombre"
          item-value="id"
          :items="estados_camp"
          :readonly="!editar"
          :filled="!editar"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-textarea
          v-model="mensaje"
          label="Mensaje predeterminado"
          rows="3"
          :readonly="!editar"
          :filled="!editar"
          auto-grow
          outlined
          dense
        ></v-textarea>
      </v-col>
    </v-row>
    <!-- Filtro clientes -->
    <v-card class="pt-4" style="width: 100%;">
      <v-row class="pa-4">
        <v-col v-if="tipo == 3" cols="6" sm="4" md="2" class="py-0">
          <v-autocomplete
            v-model="sucursal_sola"
            label="Sucursal"
            item-text="nombre"
            item-value="id"
            :items="sucursales"
            :readonly="!editar || !nueva || tipo == null"
            :filled="!editar || !nueva || tipo == null"
            outlined
            dense
            @change="limpiarClientes()"
          ></v-autocomplete>
        </v-col>
        <v-col v-else cols="12" sm="4" class="py-0">
          <v-autocomplete
            v-model="sucursal"
            label="Sucursales"
            item-text="nombre"
            item-value="id"
            :items="sucursales"
            :readonly="!editar || !nueva || tipo == null"
            :filled="!editar || !nueva || tipo == null"
            :clearable="nueva"
            deletable-chips
            small-chips
            multiple
            outlined
            dense
            @change="limpiarClientes()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <v-autocomplete
            v-model="rubro"
            label="Rubros"
            item-text="nombre"
            item-value="codigo"
            :items="rubros"
            :readonly="!editar || !nueva || fidelizacion == 1"
            :filled="!editar || !nueva"
            :clearable="nueva"
            deletable-chips
            small-chips
            multiple
            outlined
            dense
            @change="get_categorias(); categoria = []; limpiarClientes()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <v-autocomplete
            v-model="categoria"
            label="Categorias"
            item-text="nombre"
            item-value="codigo"
            :items="categorias_filtradas"
            :readonly="!editar || !nueva || fidelizacion == 1"
            :filled="!editar || !nueva"
            :clearable="nueva"
            deletable-chips
            small-chips
            multiple
            outlined
            dense
            @change="limpiarClientes()"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="5" class="py-0">
          <v-row>
            <v-col>
              <FechaPicker
                v-model="desde"
                label="Fecha Desde"
                :disabled="!editar || !nueva"
                @change="limpiarClientes()"
              />
            </v-col>
            <v-col>
              <FechaPicker
                v-model="hasta"
                label="Fecha Hasta"
                :disabled="!editar || !nueva"
                @change="limpiarClientes()"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <v-row>
            <v-col>
              <v-text-field-money
                v-model.trim="minimo"
                label="Importe Mínimo"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  readonly: !editar || !nueva || fidelizacion == 1,
                  filled: !editar || !nueva,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 0,
                  empty: null
                }"
                @change="limpiarClientes()"
              />
            </v-col>
            <v-col>
              <v-text-field-money
                v-model.trim="maximo"
                label="Importe Máximo"
                v-bind:properties="{
                  prefix: '$',
                  type: 'tel',
                  readonly: !editar || !nueva || fidelizacion == 1,
                  filled: !editar || !nueva,
                  outlined: true,
                  dense: true
                }"
                v-bind:options="{
                  locale: 'es-AR',
                  precision: 0,
                  empty: null
                }"
                @change="limpiarClientes()"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="tipo == 3" cols="12" sm="9" class="d-flex py-0">
          <v-row>
            <v-col cols="6" sm="3">
              <v-checkbox
                v-model="efectivo"
                label="Solo efectivo"
                class="mt-0 mr-3"
                :disabled="!editar || !nueva || fidelizacion == 1"
                @change="forma_cobro = []; limpiarClientes()"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="9">
              <v-autocomplete
                v-model="forma_cobro"
                label="Formas cobro"
                item-text="nombre"
                item-value="id"
                :items="formas_cobro"
                :readonly="!editar || !nueva || efectivo || fidelizacion == 1"
                :filled="!editar || !nueva || efectivo"
                :clearable="nueva"
                deletable-chips
                small-chips
                multiple
                outlined
                dense
                @change="limpiarClientes()"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="3" class="py-0" v-if="tipo == 3">
          <v-checkbox
            v-model="fidelizacion"
            label="Fidelización"
            :true-value="1"
            :false-value="0"
            class="mt-0"
            :disabled="!editar || !nueva"
          ></v-checkbox>
        </v-col>
        <v-col v-if="nueva" cols="12" class="d-flex justify-end pt-0">
          <v-btn
            color="info"
            :loading="load"
            :disabled="!editar"
            @click="buscar()"
          >
            Buscar clientes
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <!-- CLIENTES -->
    <v-data-table
      v-if="nueva"
      v-model="selected"
      item-key="codigo"
      class="elevation-2 mt-4 cebra"
      style="width: 100%;"
      sort-by="nombre"
      :items="clientes"
      :headers="headers"
      :footer-props="{
        'items-per-page-options':[ 10, 50, 100 ],
        'show-first-last-page': true,
        'show-current-page': true
      }"
      :show-select="editar"
      mobile-breakpoint
      dense
      @toggle-select-all="selectAllToggle"
    >
      <template v-slot:top>
        <div class="pa-1">
          <SelectedCounter
            :selected.sync="selected"
            :items.sync="clientes"
            :custom="true"
            :disabled="!editar"
            @criterio="select_all()"
          />
        </div>
      </template>
      <template v-slot:[`item.compras`]="{ value }">
        {{ value.join(', ') }}
      </template>
      <template v-slot:no-data>
        <v-alert
          class="mx-auto mt-4"
          max-width="400"
          type="warning"
          border="left"
          dense
          text
        >
          No hay datos para los filtros seleccionados
        </v-alert>
      </template>
    </v-data-table>
    <!--  ACCIONES -->
    <v-row v-if="nueva" class="d-flex justify-end pa-3 my-0">
      <v-btn
        color="amber"
        class="mt-2"
        :disabled="clientes.length == 0 || !editar"
        :small="$vuetify.breakpoint.xs"
        @click="limpiarTelefonos()"
      >
        <v-icon small left>fas fa-phone-slash</v-icon>
        Limpiar clientes sin telefono
      </v-btn>
      <v-btn
        color="orange"
        class="ml-3 mt-2"
        :disabled="clientes.length == 0 || !editar"
        :small="$vuetify.breakpoint.xs"
        @click="dialog = true"
      >
        <v-icon small left>fas fa-user-slash</v-icon>
        Limpiar existentes en otra camapaña
      </v-btn>
      <v-btn
        color="info"
        class="ml-3 mt-2"
        :disabled="clientes.length == 0 || !editar"
        :small="$vuetify.breakpoint.xs"
        @click="select_all()"
      >
        <v-icon small left>fas fa-check-square</v-icon>
        Seleccionar todos
      </v-btn>
    </v-row>
    <!-- BOTONES -->
    <v-col cols="12" class="d-flex justify-end px-0">
      <v-btn
        :color="editar ? 'error' : 'secondary'"
        @click="cerrar()"
      >
        {{ editar ? 'Cancelar' : 'Volver'}}
      </v-btn>
      <BtnConfirmar
        v-if="editar"
        clase="ml-3"
        :texto="nueva ? `¿Desea guardar la campaña <strong>${nombre}</strong> con <strong>${selected.length}</strong> clientes?`
          : '¿Desea guardar los cambios realizados?'"
        :disabled="(selected.length == 0 && nueva) || !nombre || !tipo"
        @action="nueva ? guardar() : modificar()"
      />
    </v-col>
    <!-- COMPONENTES -->
    <ModalBuscarCampVue
      v-model="dialog"
      @seleccion="filtrarCampanias"
    />
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { order_list_by, cadenaVacia } from '../../util/utils'
import FechaPicker from '../../components/util/FechaPicker'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SelectedCounter from '../../components/util/SelectedCounter'
import ModalBuscarCampVue from '../../components/generales/ModalBuscarCamp.vue'

export default {
  data () {
    return {
      id: this.$route.params.id,
      load: false,
      dialog: false,
      editar: false,
      nueva: false,
      efectivo: false,
      sucursal_sola: null,
      nombre: null,
      tipo: null,
      estado_id: null,
      mensaje: null,
      desde: null,
      hasta: null,
      minimo: null,
      maximo: null,
      rubro: [],
      categoria: [],
      categorias_filtradas: [],
      forma_cobro: [],
      sucursal: [],
      clientes: [],
      selected: [],
      headers: [
        { text: 'Nº pedido/s', value: 'compras', width: '100' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Domicilio', value: 'domicilio' },
        { text: 'Email', value: 'email' },
        { text: 'Teléfono', value: 'telefono', width: '100' }
      ],
      campania: {
        nombre: null,
        estado_id: null,
        mensaje: null
      },
      rules: {
        required: value => !!value || 'Campo requerido'
      },
      fidelizacion: 0
    }
  },
  async created () {
    let res = await this.$store.dispatch('encuestas/getPermiso',{ funcion_codigo: parseInt(151) });//await this.$store.dispatch('encuestas/get_permisos')
    if(res.resultado == 0){
      return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
    }
    let permiso = res.permiso;
    if (permiso != 1) this.id = -1

    this.$store.state.loading = true
    await this.$store.dispatch('encuestas/get_tipos')
    await this.$store.dispatch('encuestas/get_estados')
    await this.$store.dispatch('encuestas/get_formas_cobro')
    await this.$store.dispatch('genericosAcc/get_sucursales')
    await this.$store.dispatch('rubros/get_rubros_habilitados')
    await this.$store.dispatch('categorias/get_categorias_habilitadas')
    this.$store.state.loading = false

    // si existe el id, obtengo los datos
    if (this.id) {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/get_campania', this.id)
      this.$store.state.loading = false

      if (result.exito != 1) {
        return this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }

      const camp = result.data
      this.rubro = camp.rubros
      this.forma_cobro = camp.formas_cobro
      this.sucursal_sola = camp.sucursal
      this.efectivo = camp.efectivo == 1
      this.sucursal = camp.sucursales
      this.categoria = camp.categorias
      this.get_categorias()

      this.campania.nombre = camp.nombre
      this.campania.estado_id = camp.estado_id
      this.campania.mensaje = camp.mensaje

      this.nombre = camp.nombre
      this.tipo = camp.tipo_id
      this.estado_id = camp.estado_id
      this.mensaje = camp.mensaje
      this.desde = camp.fecha_desde
      this.hasta = camp.fecha_hasta
      this.minimo = camp.monto_minimo
      this.maximo = camp.monto_maximo
      this.fidelizacion = cadenaVacia(camp.fidelizacion) ? 0 : camp.fidelizacion
    }
    else {
      this.editar = true
      this.nueva = true
    }
  },
  computed: {
    ...mapState('rubros', ['rubros']),
    ...mapState('categorias', ['categorias']),
    ...mapState('genericosAcc', ['sucursales']),
    ...mapState('encuestas', ['tipos_camp', 'estados_camp', 'formas_cobro'])
  },
  components: {
    FechaPicker,
    BtnConfirmar,
    SelectedCounter,
    ModalBuscarCampVue
  },
  watch: {
    editar (val) {
      if (!val) {
        // vuevlo a dejar los datos como estaban
        this.campania.nombre = JSON.parse(JSON.stringify(this.nombre))
        this.campania.estado_id = JSON.parse(JSON.stringify(this.estado_id))
        this.campania.mensaje = JSON.parse(JSON.stringify(this.mensaje))
      }
    },
    fidelizacion: function(){
      if (this.fidelizacion == 1){
        // restablecer los filtros
        this.sucursal_sola = null
        this.sucursal = []
        this.rubro = []
        this.categoria = []
        this.minimo = null
        this.maximo = null
        this.efectivo = false
        this.forma_cobro = []
      }
    }
  },
  methods: {
    async modificar () {
      // solo guardo si modifico
      if (JSON.stringify(this.nombre) == JSON.stringify(this.campania.nombre) &&
          JSON.stringify(this.estado_id) == JSON.stringify(this.campania.estado_id) &&
          JSON.stringify(this.mensaje) == JSON.stringify(this.campania.mensaje)) {
        return this.$store.dispatch('show_snackbar', {
          text: 'No se encontraron cambios para guardar',
          color: 'info'
        })
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/editar_camp', {
        id: this.id,
        nombre: this.nombre,
        estado: this.estado_id,
        mensaje: this.mensaje
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        this.editar = false
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    },
    async guardar () {
      const focalizada = this.tipo == 3

      if (focalizada && !this.sucursal_sola) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una sucursal',
          color: 'orange'
        })
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/nueva_camp', {
        nombre: this.nombre,
        tipo: this.tipo,
        mensaje: this.mensaje,
        desde: this.desde,
        hasta: this.hasta,
        minimo: this.minimo,
        maximo: this.maximo,
        rubros: this.rubro,
        sucursal: this.sucursal_sola,
        efectivo: this.efectivo ? 1 : 0,
        forma_cobro: this.forma_cobro,
        focalizada: focalizada ? 1 : 0,
        categorias: this.categoria,
        sucursales: this.sucursal,
        clientes: this.selected,
        fidelizacion: this.fidelizacion
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        this.editar = false
        this.$swal.fire({
          icon: 'success',
          title: result.message
        })
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    },
    async buscar () {
      if (!this.desde || !this.hasta) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una fecha desde y hasta',
          color: 'orange'
        })
      }

      const focalizada = this.tipo == 3

      if (focalizada && !this.sucursal_sola) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una sucursal',
          color: 'orange'
        })
      }

      this.selected = []
      this.clientes = []

      this.load = true
      let result = await this.$store.dispatch('encuestas/get_clientes', {
        desde: this.desde,
        hasta: this.hasta,
        minimo: this.minimo,
        maximo: this.maximo,
        rubros: this.rubro,
        sucursal: this.sucursal_sola,
        efectivo: this.efectivo ? 1 : 0,
        forma_cobro: this.forma_cobro,
        focalizada: focalizada ? 1 : 0,
        sucursales: this.sucursal,
        categorias: this.categoria,
        fidelizacion: this.fidelizacion
      })
      this.load = false

      if (result.exito == 1) {
        this.clientes = result.data
      } else {
        return this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async filtrarCampanias (excluidas) {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/repetidos_camp', {
        clientes: this.clientes.map(cli => cli.codigo),
        camps: excluidas.map(ca => ca.id)
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        let repetidos = result.data
        let diferencia = this.clientes.filter(cliente => !repetidos.includes(cliente.codigo))

        if (diferencia.length == this.clientes.length) {
          return this.$store.dispatch('show_snackbar', {
            text: 'No se encontraron clientes repetidos en las campañas seleccionadas',
            color: 'info'
          })
        }

        let clientes = []
        let selected = []
        for (const cliente of diferencia) {
          clientes.push(cliente)
          if (this.selected.indexOf(cliente) != -1) selected.push(cliente)
        }
        this.clientes = clientes
        this.selected = selected

        this.$store.dispatch('show_snackbar', {
          text: 'Se quitaron los clientes existentes en las campañas seleccionadas',
          color: 'success'
        })
        
      } else {
        this.$swal.fire({
          icon: 'error',
          title: result.message
        })
      }
    },
    async limpiarTelefonos () {
      let clientes = []
      let selected = []
      for (const cliente of this.clientes) {
        if (cliente.telefono && cliente.telefono.length >= 9) {
          clientes.push(cliente)
          if (this.selected.indexOf(cliente) != -1) selected.push(cliente)
        }
      }
      if (clientes.length == this.clientes.length) {
        this.$store.dispatch('show_snackbar', {
          text: 'No se encontraron clientes sin teléfono',
          color: 'info'
        })
      } else {
        this.clientes = clientes
        this.selected = selected
        this.$store.dispatch('show_snackbar', {
          text: 'Se quitaron los clientes sin teléfono',
          color: 'success'
        })
      }
    },
    get_categorias () {
      this.categorias_filtradas = []
      this.rubro.forEach(rub => {
        let categorias = this.categorias.filter(cat => cat.rubro_codigo == rub)
        categorias.forEach(cat => {
          this.categorias_filtradas.push(cat)
        })
      })
      order_list_by(this.categorias_filtradas, 'nombre')
    },
    limpiarClientes () {
      this.clientes = []
      this.selected = []
    },
    select_all () {
      this.selected = []
      this.clientes.forEach(cli => { this.selected.push(cli) })
    },
    selectAllToggle () {
      if (this.selected.length < this.clientes.length) this.select_all()
      else this.selected = []
    },
    cerrar () {
      window.close()
    }
  }
}
</script>