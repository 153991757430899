<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-btn
            v-if="permiso"
            style="position: absolute; top: -15px; right: 55px; z-index: 1"
            color="success"
            title="Nueva campaña de Calidad"
            small
            fab
            @click="open_window(null)"
          >
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="12" sm="4" class="py-1">
                  Nombre
                  <v-text-field
                    v-model.trim="filtro.nombre"
                    tabindex="1"
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Tipo
                  <v-autocomplete
                    v-model="filtro.tipo"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipos_camp"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="2" class="py-1">
                  Estado
                  <v-autocomplete
                    v-model="filtro.estado"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="estados_camp"
                    hide-details
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <BtnFiltro
                  :loading="load"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- DATA TABLE CAMPAÑAS -->
      <v-data-table
        v-model="selected"
        class="cebra elevation-2 mt-2"
        sort-by="fecha_grabacion"
        :headers="headers"
        :items="campanias"
        :loading="load"
        mobile-breakpoint
        single-select
        show-select
        sort-desc
        dense
        @click:row="rowClick"
      >
        <template v-slot:[`item.fecha_grabacion`]="{ value }">
          {{ moment(value).format('DD/MM/YYYY') }}
        </template>
        <template v-slot:[`item.estado_nombre`]="{ item }">
          <v-chip
            :color="item.estado_color"
            small
          >
            {{ item.estado_nombre }}
          </v-chip>
        </template>
        <template v-slot:[`item.mensaje`]="{ value }">
          <v-tooltip v-if="value != null" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="purple"
                small
              >
                fas fa-envelope-open-text
              </v-icon>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="info"
            title="Ver"
            class="mr-2"
            small
            @click.stop="open_window(item.id)"
          >
            fas fa-eye
          </v-icon>
          <BtnConfirmar
            v-if="item.estado_id == 1"
            clase="mr-2"
            icono="fas fa-ban"
            color="error"
            title="Inhabilitar"
            :texto="`¿Desea inhabilitar la campaña <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="inhabilitar(item)"
          />
          <BtnConfirmar
            v-if="item.estado_id == 1"
            clase="mr-2"
            icono="fas fa-flag-checkered"
            color="success"
            title="Finalizar"
            :texto="`¿Desea finalizar la campaña <strong>${item.nombre}</strong>?`"
            :icon_button="true"
            :small="true"
            @action="finalizar(item)"
          />
        </template>
        <template v-slot:no-data>
          <v-alert
            class="mx-auto mt-4"
            max-width="400"
            type="warning"
            border="left"
            dense
            text
          >
            No hay datos para los filtros seleccionados
          </v-alert>
        </template>
      </v-data-table>
      <!-- DATA TABLE CLIENTES -->
      <v-data-table
        v-if="selected.length == 1"
        v-model="selected_clientes"
        class="cebra elevation-2 mt-2 prevent-select"
        sort-by="nombre"
        :headers="headers_clientes"
        :items="clientes.filter(cli => (cli.sucursal == sucursal || sucursal == null) && (cli.vendedor == vendedor || vendedor == null))"
        :loading="load_clientes"
        :search="search"
        :show-select="selected[0].estado_id == 1"
        :item-class="itemRowBackground"
        :footer-props="{
          'items-per-page-options':[ 10, null ],
          'show-first-last-page': true,
          'show-current-page': true
        }"
        mobile-breakpoint
        dense
      >
        <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
          <v-simple-checkbox
            v-if="item.enviado != 1"
            :value="isSelected"
            :readonly="item.disabled"
            :disabled="item.disabled"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>
        <template v-slot:top>
          <v-row class="d-flex justify-end pa-2" no-gutters>
            <v-col cols="12" sm="6" xl="4" class="mt-1">
              <v-row>
                <div class="d-flex align-center py-2 pl-3" style="font-size: 20px;">Clientes - {{ selected[0].nombre }}</div>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6" xl="8" class="mt-3 mt-sm-0">
              <v-row class="d-flex justify-end">
                <v-col v-if="selected[0].focalizada != 1" cols="6" sm="4">
                  <v-autocomplete
                    v-model="sucursal"
                    item-text="nombre"
                    item-value="id"
                    placeholder="Sucursal"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="selected_clientes = []"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="4">
                  <v-autocomplete
                    v-model="enviado"
                    :items="estados"
                    hide-details
                    outlined
                    dense
                    @change="get_detalles()"
                  ></v-autocomplete>
                </v-col>
                <v-col :cols="selected[0].focalizada == 1 ? 6 : 12" sm="4">
                  <SearchDataTable
                    v-model="search"
                  />
                </v-col>
                <v-col v-if="selected[0].focalizada == 1 && codigo_vend == null" cols="12" sm="8" class="mt-0 mt-sm-n3">
                  <v-autocomplete
                    v-model="vendedor"
                    item-text="nombre"
                    item-value="codigo"
                    placeholder="Vendedor"
                    :items="vendedores"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="selected_clientes = []"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.compras`]="{ value }">
          {{ value.join(', ') }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
          v-if="item.telefono != null"
            color="wpp"
            title="Enviar WhatsApp"
            class="mr-2"
            @click="enviar_wpp(item)"
          >
            fab fa-whatsapp
          </v-icon>
          <template v-if="item.enviado != 1 && selected[0].estado_id == 1">
            <v-icon
              v-if="item.telefono != null"
              color="success"
              title="Marcar como enviado"
              class="mr-2"
              small
              @click="marcar_enviado(item)"
            >
              fas fa-check
            </v-icon>
            <BtnConfirmar
              v-if="permiso"
              clase="mr-2"
              icono="fas fa-trash"
              color="error"
              title="Eliminar"
              :texto="`¿Desea eliminar del detalle al cliente <strong>${item.nombre}</strong>? (esta acción no se puede deshacer)`"
              :icon_button="true"
              :small="true"
              @action="eliminar(item)"
            />
          </template>
          <v-icon
            v-if="selected[0].estado_id == 1"
            color="teal"
            title="Agregar observación"
            small
            @click="detalle = item; dialog_obs = true"
          >
            fas fa-comment-dots
          </v-icon>
        </template>
        <template v-slot:[`item.observaciones`]="{ value }">
          <v-tooltip v-if="value != null && value != ''" left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="primary"
                small
              >
                fas fa-info-circle
              </v-icon>
            </template>
            <span>{{ value }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-col cols="12" class="d-flex justify-end px-0">
        <SelectedCounter
          :selected.sync="selected_clientes"
          :items.sync="clientes"
          :custom="true"
          @criterio="select_all()"
        />
        <v-spacer></v-spacer>
        <v-btn
          title="Descargar Excel"
          :disabled="clientes.length == 0"
          @click="descargarExcel()"
          v-if="selected.length == 1 && permisoDescarga == 1"
          class="mr-2"
        >
          Excel
          <v-icon
            color="green darken-3"
            :small="$vuetify.breakpoint.xs"
            right
          >
            fas fa-file-download
          </v-icon>
        </v-btn>
        <v-btn
          v-if="selected.length == 1 && selected[0].tipo_id == 3"
          class="mr-3"
          color="cyan"
          :disabled="selected_clientes.length < 1"
          :dark="selected_clientes.length >= 1"
          @click="dialog_reasignar = true"
        >
          <v-icon small left>fas fa-users</v-icon>
          Reasignar
        </v-btn>
        <BtnConfirmar
          v-if="selected.length == 1"
          nombre="Marcar como enviados"
          icono="fas fa-check"
          :texto="`¿Desea marcar como <strong>enviado</strong> a los <strong>${selected_clientes.length}</strong> clientes seleccionados?`"
          :disabled="selected_clientes.length < 2"
          @action="marcar_enviado(null)"
        />
      </v-col>
    </v-col>
    <!-- modal de comentarios -->
    <v-dialog
      v-model="dialog_obs"
      width="400"
      :persistent="load_obs"
    >
      <v-card>
        <v-card-title>
         {{ observacion == null ? 'Agregar' : 'Editar' }} observación
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model.trim="observacion"
            rows="3"
            :readonly="load_obs"
            :filled="load_obs"
            hide-details
            autofocus
            auto-grow
            outlined
            dense
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            :disabled="load_obs"
            @click="dialog_obs = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :loading="load_obs"
            @click="guardar_comentario()"
          >
            <v-icon left>fas fa-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- modal de reasignar -->
     <v-dialog
      v-model="dialog_reasignar"
      width="400"
    >
      <v-card>
        <v-card-title>
          Reasignar clientes
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="reasignar"
            item-text="nombre"
            item-value="codigo"
            placeholder="Vendedor"
            :items="vendedores.filter(ve => ve.codigo != 0)"
            hide-details
            outlined
            dense
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            @click="dialog_reasignar = false"
          >
            Cancelar
          </v-btn>
          <BtnConfirmar
            nombre="Confirmar"
            :texto="`¿Desea reasignar ${selected_clientes.length == 1 ? 'al' : `a los <strong>${selected_clientes.length}</strong>`}
              cliente${selected_clientes.length > 1 ? 's' : ''} seleccionado${selected_clientes.length > 1 ? 's' : ''} al vendedor
              <strong>${vendedores.find(ve => ve.codigo == reasignar) ? vendedores.find(ve => ve.codigo == reasignar).nombre : ''}</strong>?`"
            :disabled="reasignar == null"
            @action="reasignar_vendedor()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import BtnConfirmar from '../../components/util/BtnConfirmar'
import SearchDataTable from '../../components/util/SearchDataTable'
import SelectedCounter from '../../components/util/SelectedCounter'
import { get_nombre_obj_arr, order_list_by } from '../../util/utils'
import download from 'downloadjs'

export default {
  data () {
    return {
      moment: moment,
      panel: 0,
      load: false,
      load_clientes: false,
      load_obs: false,
      permiso: false,
      permisoDescarga: 0,
      dialog: false,
      dialog_obs: false,
      dialog_reasignar: false,
      sucursal: null,
      vendedor: null,
      reasignar: null,
      codigo_vend: null,
      observacion: '',
      search: '',
      enviado: 0,
      detalle: {},
      campanias: [],
      clientes: [],
      selected: [],
      selected_clientes: [],
      vendedores: [],
      estados: [
        { text: 'Enviado', value: 1 },
        { text: 'No enviado', value: 0 },
        { text: 'Todos', value: '' }
      ],
      headers: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Tipo', value: 'tipo_nombre' },
        { text: 'Estado', value: 'estado_nombre' },
        { text: 'Fecha grabación', value: 'fecha_grabacion' },
        { text: 'Uusario grabación', value: 'usuario_grabacion' },
        { text: 'Cant. clientes', value: 'cant_clientes' },
        { text: 'Msj.', value: 'mensaje' }
      ],
      headers_clientes: [
        { text: 'Nº pedido/s', value: 'compras', width: '100' },
        { text: 'Fecha', value: 'fecha' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Documento', value: 'cliente_documento' },
        { text: 'Domicilio', value: 'domicilio' },
        { text: 'Email', value: 'email' },
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Obs.', value: 'observaciones' },
        { text: 'Acciones', value: 'actions', sortable: false, filterable: false }
      ],
      filtro: {
        nombre: '',
        estado: '',
        tipo: ''
      }
    }
  },
  async created () {
    await this.getPermisos();
    // si no tiene permisos busco y asigno el codigo de vendedor
    if (!this.permiso) {
      this.$store.state.loading = true
      await this.$store.dispatch('objetivos/get_codigo_vend')
        .then(async (res) => {
          // si no tiene codigo de vendedor le asigno -1 al codigo para q no muestre nada
          this.codigo_vend = res.data ? res.data : -1
        })
        .catch((error) => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    }

    // obtengo el resto de datos
    this.$store.state.loading = true
    await this.$store.dispatch('encuestas/get_tipos')
    await this.$store.dispatch('encuestas/get_estados')
    await this.$store.dispatch('genericosAcc/get_sucursales')
    this.$store.state.loading = false

    if (this.permiso) this.headers.push({ text: 'Acciones', value: 'actions', sortable: false, filterable: false })
  },
  computed: {
    ...mapState('genericosAcc', ['sucursales']),
    ...mapState('encuestas', ['tipos_camp', 'estados_camp'])
  },
  components: {
    BtnFiltro,
    BtnConfirmar,
    SearchDataTable,
    SelectedCounter
  },
  watch: {
    dialog_reasignar (val) {
      val || (this.reasignar = null)
    },
    async selected () {
      this.enviado = 0
      this.sucursal = null
      this.vendedor = null
      this.clientes = []
      this.selected_clientes = []
      if (this.selected.length == 1) {
        if (this.selected[0].estado_id != 1) this.enviado = ''
        this.get_detalles()
        // si es focalizada obtengo los vendedores de la sucursal
        if (this.selected[0].focalizada == 1) {
          if (this.codigo_vend) {
            // si tiene codigo de vendedor, lo selecciono
            this.vendedor = this.codigo_vend
          } else {
            // si no tiene codigo de vendedor obtengo todos los vendedores
            this.$store.state.loading = true
            await this.$store.dispatch('vendedores/get_vendedores', {
              inhabilitado: 0,
              categoria: 5,
              empresa: 2,
              sucursal: this.selected[0].sucursal
            })
            .then((data) => {
              this.vendedores = data.vendedores
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error'
              })
            })
            this.$store.state.loading = false
            order_list_by(this.vendedores, 'nombre')
            this.vendedores.unshift({
              codigo: 0,
              nombre: 'SIN VENDEDOR'
            })
          }
        }
      }
    },
    dialog_obs (val) {
      if (val) this.observacion = JSON.parse(JSON.stringify(this.detalle.observaciones))
    }
  },
  methods: {
    async getPermisos(){
      // obtengo los permisos
      this.$store.state.loading = true
      let res1 = await this.$store.dispatch('encuestas/getPermiso',{ funcion_codigo: parseInt(151) });
      if(res1.resultado == 0){
        this.$store.state.loading = false
        return this.$store.dispatch('show_snackbar', { text: res1.message, color: 'error' })
      }
      this.permiso = res1.permiso;
      this.permiso = this.permiso == 1? true : false; //lo dejo así ya que permiso se considera boolean en el código
      
      const res = await this.$store.dispatch('encuestas/getPermiso',{ funcion_codigo: parseInt(155) });
      if(res.resultado == 0){
        this.$store.state.loading = false
        return this.$store.dispatch('show_snackbar', { text: res.message, color: 'error' })
      }
      this.permisoDescarga = res.permiso; //será tratado numérico 0 = no tiene, 1 = tiene permiso
      this.$store.state.loading = false

    },  
    async reasignar_vendedor () {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/reasignar_vendedor', {
        id: this.selected[0].id,
        vendedor: this.reasignar,
        clientes: this.selected_clientes.map(cli => cli.codigo)
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        // asigno el nuevo vendedor a todos los seleccionados
        this.selected_clientes.forEach(cliente => {
          cliente.vendedor = this.reasignar
        })
        this.selected_clientes = []
        this.dialog_reasignar = false
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async enviar_wpp (item) {
      // 12-03-2024 por MMURILLO, primero guardo el click que le hicieron
      this.$store.state.loading = true
      let logPeticion = await this.$store.dispatch('encuestas/set_click_wpp', {id: item.id})
      this.$store.state.loading = false
      if (logPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: logPeticion.message,
          color: 'error',
        })
      }
      let mensaje = this.selected[0].mensaje ? `/?text=${this.selected[0].mensaje}` : ''
      window.open(`https://wa.me/549${item.telefono}${mensaje}`, '_blank')
    },
    async guardar_comentario () {
      if (JSON.stringify(this.observacion) == JSON.stringify(this.detalle.observaciones)) {
        this.dialog_obs = false
        return this.$store.dispatch('show_snackbar', {
          text: 'No se realizaron modificaciones en la observación',
          color: 'info'
        })
      }

      this.load_obs = true
      let result = await this.$store.dispatch('encuestas/agregar_comentario', {
        id: this.selected[0].id,
        cliente: this.detalle.codigo,
        comentario: this.observacion
      })
      this.load_obs = false

      if (result.exito == 1) {
        this.detalle.observaciones = this.observacion
        this.dialog_obs = false
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async marcar_enviado (item) {
      // si el item existe es individual, sino es masivo
      let clientes = []
      if (item) {
        clientes = [item.codigo]
      } else {
        clientes = this.selected_clientes.map(cli => cli.codigo)
      }

      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/marcar_enviados', {
        id: this.selected[0].id,
        clientes: clientes
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        if (item) {
          // si el item esta seleccionado lo deselecciono pq ya lo marcó :^v
          const index = this.selected_clientes.indexOf(item)
          if (index != -1) this.selected_clientes.splice(index, 1)
          item.enviado = 1
        } else {
          // marco todos como enviados y limpio
          this.selected_clientes.forEach(cliente => {
            cliente.enviado = 1
          })
          this.selected_clientes = []
        }
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async eliminar (item) {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/eliminar_detalle', {
        id: this.selected[0].id,
        cliente: item.codigo
      })
      this.$store.state.loading = false

      if (result.exito == 1) {
        // obtengo los indices de los dos array
        const index = this.clientes.indexOf(item)
        const index_sel = this.selected_clientes.indexOf(item)

        this.clientes.splice(index, 1)
        // si esta seleccionado tmb lo elimino ahi
        if (index_sel != -1) this.selected_clientes.splice(index_sel, 1)

        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async finalizar (item) {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/finalizar_camp', item.id)
      this.$store.state.loading = false

      if (result.exito == 1) {
        item.estado_id = 3
        item.estado_nombre = get_nombre_obj_arr(this.estados_camp, 'id', 3, 'nombre')
        item.estado_color = get_nombre_obj_arr(this.estados_camp, 'id', 3, 'color')
        this.selected_clientes = []
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async inhabilitar (item) {
      this.$store.state.loading = true
      let result = await this.$store.dispatch('encuestas/inhabilitar_camp', item.id)
      this.$store.state.loading = false

      if (result.exito == 1) {
        item.estado_id = 2
        item.estado_nombre = get_nombre_obj_arr(this.estados_camp, 'id', 2, 'nombre')
        item.estado_color = get_nombre_obj_arr(this.estados_camp, 'id', 2, 'color')
        this.selected_clientes = []
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'success'
        })
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async buscar () {
      this.search = ''
      this.selected = []
      this.campanias = []

      this.load = true
      let result = await this.$store.dispatch('encuestas/get_campanias', this.filtro)
      this.load = false

      if (result.exito == 1) {
        this.campanias = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    async get_detalles () {
      this.clientes = []
      this.selected_clientes = []

      this.load_clientes = true
      let result = await this.$store.dispatch('encuestas/get_detalles', {
        camp_id: this.selected[0].id,
        enviado: this.enviado
      })
      this.load_clientes = false

      if (result.exito == 1) {
        this.clientes = result.data
      } else {
        this.$store.dispatch('show_snackbar', {
          text: result.message,
          color: 'error'
        })
      }
    },
    open_window (id) {
      let path = '/camp-encuesta'
      if (id) path = `${path}/${id}`
      const routeData = this.$router.resolve({ path: path })
      window.open(routeData.href, '_blank')
    },
    select_all () {
      this.selected_clientes = []
      this.clientes.forEach(cli => {
        if (cli.enviado != 1 && (cli.sucursal == this.sucursal || this.sucursal == null) && (cli.vendedor == this.vendedor || this.vendedor == null)) {
          this.selected_clientes.push(cli)
        }
      })
    },
    rowClick (item, row) {
      let selectState = (row.isSelected) ? false : true
      row.select(selectState)
    },
    itemRowBackground (item) {
      return item.enviado == 1 ? 'background-color: rowsuc' : 'rowwar'
    },
    limpiar () {
      this.filtro = {
        nombre: '',
        estado: '',
        tipo: ''
      }
    },
    async descargarExcel (){
      this.$store.state.loading = true
      let excelPeticion = await this.$store.dispatch('encuestas/get_excel_campanias', {id: this.selected[0].id})
      this.$store.state.loading = false
      if (excelPeticion.exito == 0){
        this.$store.dispatch('show_snackbar', {
          text: excelPeticion.message,
          color: 'error',
        })
        return
      }
      download(excelPeticion.base64, `${this.selected[0].nombre}.xlsx`, 'application/vnd.ms-excel')
    }
  }
}
</script>

<style scoped>
  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
</style>